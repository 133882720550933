const data = [
        {
        id: 1,
        question: "What was their technical responsibility for and at what level?",
        answer: "He is good with technology – he was responsible for maintaining and managing the applications and he is a very fast learner of technology."
        },
        {
        id: 2,
        question: "How would you rate the technical performance on a scale of 1 - 10 or fair to excellent? If not a 10 or excellent, why?",
        answer: "Technical performance – 8-9 , no one knows 100%, however he will do the research and find a solution for the problem."
        },
        {
        id: 3,
        question: "What would you say their greatest strength is?",
        answer: "His technical ability and understanding the business to solve their problems."
        },
        {
        id: 4,
        question: "Do they work well with others? (Better on a team or independently?)",
        answer: "He can work independently as well as in the team."
        },
        {
        id: 5,
        question: "Are they reliable? (Meet deadlines, attendance, and punctuality) professional?",
        answer: "Yes"
        },
        {
        id: 6,
        question: "Would they be eligible for rehire? If so, what type of job? Do you have any draw backs in recommending them?",
        answer: "I would rehire him, no drawbacks."
        },
        {
        id: 7,
        question: "Overall, 1 to 10 (10 being perfect) how would you rate them?",
        answer: "8-9"
        }
    ]

    export default data
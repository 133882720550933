import './portfolio.css'
import Projects from './Projects'
import ProjectsCategories from './ProjectsCategories'
import data from './data'
import { useState } from 'react'

const Portfolio = () => {
  const [projects, setProjects] = useState(data);

  const categories = data.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];
  
  const filterProjectsHandler = (category) => {
    if(category === "all") {
      setProjects(data);
      return;
    }

    const filterProjects = data.filter(project => project.category === category);
    setProjects(filterProjects);
  }
  

  return (
    // <section id="portfolio">
    //   <h2>Recent Projects</h2>
    //   <p>
    //   These are merely theoretical projects and are not intended to reveal any copyrighted projects or breach any copyright regulations related to my past work at previous companies.
    //   </p>
    //   <div className="container portfolio__container">
    //     <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
    //     <Projects projects={projects}/>
    //   </div>
    // </section>
    <section id="portfolio">
      <h2>Recent Projects</h2>
      <p>
      Under renovation - emerging soon. Stay tuned!
      </p>
      {/* <div className="container portfolio__container">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={projects}/>
      </div> */}
    </section>
  )
}

export default Portfolio
import {AiFillGithub} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#services', title: 'Services'},
    {id: 4, link: '#portfolio', title: 'Projects'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/melvin-manuel-481746258/', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://github.com/MelvinLoManuel', icon: <AiFillGithub/>},
]
import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'

const data = [
    {
        id: 1, icon: <SiAdobexd/>, title: 'UI/UX Design', desc: "I create modern and intuitive designs by adhering to industry-standard guidelines, ensuring user enjoyment and engagement with your product."
    },
    {
        id: 2, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Your product will exhibit a visually appealing design and maintain accessibility across all devices, including mobile phones, tablets, and desktops."
    },
    {
        id: 3, icon: <FaServer/>, title: 'Backend Development', desc: "The protection of your business/product is prioritized from the project’s inception, ensuring your website/app is fortified against attacks."
    },
    {
        id: 4, icon: <AiFillAppstore/>, title: 'App Development', desc: "I will create an app compatible with both iOS and Android devices at no additional cost to you."
    }
]

export default data
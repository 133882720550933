const data = [
    {
      id: 1,
      question: "What is your current employment eligibility in Canada?",
      answer: "I hold permanent residency in Canada."
    },
    {
      id: 2,

      question: "What was the reason for leaving your previous job?",
      answer: "I relocated to Ontario, Canada."
    },
    {
      id: 5,
      question: "When would you be available to begin working?",
      answer: "I would be able to start working after a one-week notice period."
    },
    {
      id: 4,
      question: "Are you able to commute dependably to any location within Ontario?",
      answer: "Yes."
    }
  ]

  export default data
import Card from "../../components/Card"

const Testimonial = ({testimonial}) => {
  return (
    <Card className="light">     
        <h5>{testimonial.question}</h5>        
        <div className="testimonial__client">
            <div className="testimonial__client-avatar">
              <p>{testimonial.answer}</p>
            </div>
        </div>
    </Card>
  )
}

export default Testimonial
import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'backend',
        image: Image1,
        title: "NodeHarbor Engine",
        desc: "A dynamic and scalable backend system focusing on optimized data processing and management to ensure seamless integration with various front-end technologies and databases.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "VividView Interface",
        desc: "A dynamic and responsive user interface framework focused on delivering vibrant and fluid user experiences. It’s optimized for high-performance rendering, ensuring smooth transitions and animations, catering to a variety of user preferences and accessibility needs.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "ElegantLeaf Design System",
        desc: "A comprehensive design system embodying minimalist aesthetics and user-centered design principles. It offers a set of modular components, layouts, and interactive elements that streamline the development of visually cohesive and user-friendly applications.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 4,
        category: 'backend',
        image: Image4,
        title: "QuantumRelay Interface",
        desc: "A revolutionary database middleware tool that uses quantum computing principles to speed up real-time data retrieval and storage. Suited for businesses with high-demand, real-time processing needs.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 5,
        category: 'uiux',
        image: Image5,
        title: "HarmonySphere Design Studio",
        desc: "An innovative UI/UX design toolkit tailored for crafting intuitive and harmonious user interfaces. Empowering designers with cutting-edge prototyping tools, collaboration features, and a vast library of pre-designed components, HarmonyCraft ensures both aesthetic appeal and optimal user engagement, bridging the gap between vision and reality.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 6,
        category: 'backend',
        image: Image6,
        title: "CortexCloud Controller",
        desc: "A cloud-native backend controller focused on leveraging machine learning and artificial intelligence to automate data analysis, streamline workflows, and predict system needs.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 7,
        category: 'frontend',
        image: Image7,
        title: "SpectraFlow Dashboard",
        desc: "A multifaceted dashboard development toolkit enabling the creation of real-time, data-driven visualizations. It empowers developers to integrate a myriad of data sources and customize presentation layers to render insightful and interactive analytics seamlessly.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 8,
        category: 'backend',
        image: Image8,
        title: "AeroFlow Orchestrator",
        desc: "A backend workflow management system designed to automate and optimize tasks in cloud environments. Leveraging containerization, it ensures rapid deployment and elastic scalability for applications.",
        github: 'https://github.com/MelvinLoManuel'
    },
    {
        id: 9,
        category: 'backend',
        image: Image9,
        title: "DeepDive Analytics Engine",
        desc: "A data processing engine that employs deep learning algorithms to parse, analyze, and visualize big data from various sources, turning raw data into actionable insights for businesses.",
        github: 'https://github.com/MelvinLoManuel'
    }
]

export default data